@tailwind base;
@tailwind components;
@tailwind utilities;
 
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
 
 
body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  /* background: url(./images/subtle-prism.svg) top center no-repeat;
  background-size: cover; */
}
 
.raleway {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-size: 18px;
  font-style: normal;
}
 
/* .hero {
  background: url(./images/bg1.png) top center no-repeat;
  background-size: cover;
} */
 
.footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
}
 
.cta {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80) fixed center center;
}
 
/* Gradient Text */
.text-gradient {
  background: linear-gradient(90deg, #00bcd4, #ff5722);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
 
/* Glassmorphism Effect */
.glass-effect {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}
 
/* Animations */
@keyframes fade-in-up {
  0% {
      opacity: 0;
      transform: translateY(20px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}
 
.animate-fade-in-up {
  animation: fade-in-up 1s ease-out;
}
 
/* Card Hover Effects */
.hover\:scale-105:hover {
  transform: scale(1.05);
}
 
.hover\:shadow-2xl:hover {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
}
 
/* Modern Card Design */
.bg-gray-850 {
  background-color: #2c2c2c;
}
 
.bg-gray-800 {
  background-color: #333;
}
 
 
 
 
.intro{
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(https://images.unsplash.com/photo-1532619675605-1ede6c2ed2b0?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDB8fGRhdGF8ZW58MHx8MHx8fDA%3D) fixed center center;
  background-size: cover;
}
 
 
.menu{
  background: url(./images/subtle-prism.svg) top center no-repeat;
  background-size: cover;
}
html {
  scroll-behavior: smooth;
}


.custom-image-size {
  width: 80%;
  max-width: 600px; /* Adjust this value as needed */
}
.modal-overlay{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


.modal-content {
  background: white;
  padding: 40px;
  border-radius: 1px;
  position: relative;
  max-width: 500px;
  width: 100%;
  height: 80vh;
  overflow: auto;
}

.modal-close {
  position: absolute;
  top: 3px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 3rem;
  cursor: pointer;
}

