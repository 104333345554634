/* NavLinks.css */

@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');


.group {
    position: relative;
    /* border: 2px solid red; */
}

.drop{
    padding-top: 3%;
    padding-bottom: 3%;
}

.group:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
}

.dropdown-menu {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: #ffffff; /* White background for the dropdown */
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s, visibility 0.3s;
    padding: 1rem;
    width: 30rem; /* Adjust width as needed */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    z-index: 10;
}

.category-column {
    background-color: #ffffff; /* White background for category columns */
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.category-title {
    font-size: 1.00rem; /* Larger size for headings */
    font-weight: bold;
    color: #333; /* Dark color for headings */
    margin-bottom: 0.5rem;
    text-align: center;
}

.product-link {
    display: block;
    padding: 0.5rem;
    background-color: #007bff; /* Blue background for product links */
    color: #ffffff; /* White text color for product links */
    text-decoration: none;
    border-radius: 0.25rem;
    transition: background-color 0.3s, color 0.3s;
    text-align: center;
    margin-bottom: 0.25rem; /* Space between items */
    font-size: 0.800rem; /* Smaller font size for product links */
}

.product-link:hover {
    background-color: #0056b3; /* Darker blue for hover effect */
}


.nav-links{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    justify-content: left;
    align-items: center;
    flex-direction: row;
    display: flex;
}





.list{
    font-family: "Jost", sans-serif;
    font-size: 14px;
}

.jost{
    font-family: "Jost", sans-serif;
    font-optical-sizing: auto;
}

.comfortaa {
    font-family: "Comfortaa", sans-serif;
    font-optical-sizing: auto;
    /* font-weight: <weight>;
    font-style: normal; */
  }



  .navTitle{
    font-family: "Comfortaa", sans-serif;
    font-optical-sizing: auto;
    font-size: 16px;
}