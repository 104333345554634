@keyframes moveAndFade {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    50% {
        opacity: 0.5;
        transform: translateY(-30px);
    }
    100% {
        opacity: 0;
        transform: translateY(-60px);
    }
}

.gratitude-text {
    font-size: 1.5rem;
    font-weight: bold;
    color: #1D4ED8; /* Blue color for the text */
    animation: moveAndFade 3s ease-in-out infinite;
}


.suggestions-list {
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
    width: 100%;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.suggestion-item {
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.suggestion-item:hover,
.suggestion-item.highlighted {
    background-color: #f0f0f0;
}

.no-results {
    padding: 8px;
    color: #999;
}


